/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * This is the starting point of your application.
 * oneAudi OS and Feature Hub Apps will use this file to bootstrap the app.
 */
import React from 'react';
import { Logger } from '@feature-hub/core';
import { App, ProviderProps as AppProps } from '@volkswagen-onehub/oneaudi-os-react';
import { GfaServiceConfigProviderV1 } from '@volkswagen-onehub/gfa-service-config-provider';
import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service/dist/v2/types';
import { TrackingServiceV3 } from '@volkswagen-onehub/oneaudi-os-onesight';
import { ContentServiceV1 } from '@volkswagen-onehub/audi-content-service';
import { createTrackingService } from '@volkswagen-onehub/oneaudi-os-adapter-tracking';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import FeatureAppRoot from './components/FeatureAppRoot';
import { TrackingServiceEnvironment } from './trackingUtils/TrackingHelperWrapper';
import { useIsSSR } from './components/useIsSSR';

interface FeatureAppProps extends AppProps {
  configProvider: GfaServiceConfigProviderV1;
  trackingService?: TrackingServiceV2 | TrackingServiceV3;
  content?: any;
  contentService?: ContentServiceV1 | undefined;
  loggerService?: Logger;
  vueFormatterService?: VueFormatterServiceInterfaceV1;
  config?: any;
  environment: string;
}

export type TrackingDemoData = {
  trackingService: TrackingServiceEnvironment;
};

const FeatureApp: React.FC<FeatureAppProps> = ({ ...inherited }: FeatureAppProps) => {
  const { content, loggerService, environment, contentService } = inherited;

  let trackingService = {} as TrackingServiceV2 | TrackingServiceV3;
  const isSSR = useIsSSR();
  if (isSSR === false) {
    trackingService = inherited.trackingService || createTrackingService(__FEATURE_APP_NAME__);
  }
  const app = {
    name: __FEATURE_APP_NAME__,
    version: __FEATURE_APP_VERSION__,
  };

  const appConfig: { [k: string]: string } = { appName: __FEATURE_APP_NAME__ };
  return (
    <App config={appConfig} app={app} {...inherited}>
      <FeatureAppRoot
        content={content}
        contentService={contentService}
        environment={environment}
        trackingService={trackingService}
        loggerService={loggerService ?? console}
      />
    </App>
  );
};

export default FeatureApp;
