/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Text } from '@audi/audi-ui-react';
import { v4 as uuid } from 'uuid';
import { renderTextWithFootnotesReferences } from '@oneaudi/feature-app-utils';
import { WLTPContainer, MediaDisclaimerContainer, ContentContainer } from './style';
import { VueServiceHelper, WltpProps } from '../../../helper/consumptionAndEmission';
import { ContentContext } from '../../context/ContentContext';

type WLTPComponentsProps = {
  wltpTextValues: string[] | null;
  mediaDisclaimer: string;
  theme: string;
};

export const WLTPComponent: React.FC<WLTPComponentsProps> = (props) => {
  const { wltpTextValues } = props;

  const context: any = React.useContext(ContentContext);
  const { vueFormatterService } = context;

  const { localeService } = vueFormatterService;

  const [vueTexts, setVueTexts] = React.useState([] as WltpProps[]);

  const { theme, mediaDisclaimer } = props;

  React.useEffect(() => {
    wltpTextValues &&
      VueServiceHelper.getConsumptionAndEmission(
        wltpTextValues,
        setVueTexts,
        vueFormatterService,
        localeService,
      );
  }, [wltpTextValues]);

  return (
    <ContentContainer theme={theme}>
      <WLTPContainer theme={theme}>
        {vueTexts &&
          vueTexts?.map(
            (
              {
                formattedConsumption,
                formattedEmission,
                formattedCo2Class,
                formattedDischargedCo2Class,
                formattedDischargedConsumption,
              },
              index,
            ) => (
              // eslint-disable-next-line react/no-array-index-key
              <Text as="p" variant="copy2" key={index}>
                <div>
                  {formattedConsumption && (
                    <span className="sm-wltp-items" tabIndex={-1} key={uuid()}>
                      {formattedConsumption}
                    </span>
                  )}
                  {formattedEmission && (
                    <span className="sm-wltp-items" tabIndex={-1} key={uuid()}>
                      {formattedEmission}
                    </span>
                  )}
                  {formattedCo2Class && (
                    <span className="sm-wltp-items" tabIndex={-1} key={uuid()}>
                      {formattedCo2Class}
                    </span>
                  )}
                  {formattedDischargedConsumption && (
                    <span className="sm-wltp-items" tabIndex={-1} key={uuid()}>
                      {formattedDischargedConsumption}
                    </span>
                  )}
                  {formattedDischargedCo2Class && (
                    <span className="sm-wltp-items" tabIndex={-1} key={uuid()}>
                      {formattedDischargedCo2Class}
                    </span>
                  )}
                </div>
              </Text>
            ),
          )}
      </WLTPContainer>
      <MediaDisclaimerContainer
        theme={theme}
        style={{ display: mediaDisclaimer ? 'block' : 'none' }}
      >
        <br />
        {mediaDisclaimer && (
          <Text as="span" variant="copy2">
            {renderTextWithFootnotesReferences(mediaDisclaimer)}
          </Text>
        )}
      </MediaDisclaimerContainer>
    </ContentContainer>
  );
};
