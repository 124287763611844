/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { ContentService } from '@volkswagen-onehub/audi-content-service';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { EnvConfigServiceV1 } from '@volkswagen-onehub/audi-env-config-service/dist/v1/EnvConfigServiceV1';
// eslint-disable-next-line
import { IPersonalizationServiceV2 } from '@volkswagen-onehub/audi-personalization-service';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@oneaudi/footnote-reference-service';
import { IAuthServiceV1 } from '@oneaudi/audi-auth-service';
import { ContentContext } from './ContentContext';
import mapContent from '../contentMapping/contentMapping';

export const ContextProvider: React.FC<{
  featureAppId: string;
  children?: React.ReactNode;
  contentService: ContentService;
  authService: IAuthServiceV1;
  // eslint-disable-next-line react/no-unused-prop-types
  envConfigService: EnvConfigServiceV1;
  localeService: GfaLocaleServiceV1;
  loggerService: any;
  personalizationService?: IPersonalizationServiceV2;
  footnoteReferenceService: AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  // vueFormatterService: VueFormatterFeatureServiceInterfaceV1; // TODO: Confirm the below is the correct replacement
  vueFormatterService: VueFormatterServiceInterfaceV1;
}> = ({
  featureAppId,
  vueFormatterService,
  authService,
  contentService,
  localeService,
  loggerService,
  personalizationService,
  footnoteReferenceService,
  children,
}) => {
  const [rawContent, setRawContent] = React.useState<any>(contentService?.getContent());

  let content = null;

  React.useEffect(() => {
    const listener = () => {
      setRawContent(contentService?.getContent());
    };
    contentService?.onContentChange(listener);

    return () => {
      contentService?.removeOnContentChange(listener);
    };
  }, [contentService]);

  content = mapContent(rawContent);

  return (
    <ContentContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        content,
        footnoteReferenceService,
        featureAppId,
        authService,
        vueFormatterService,
        localeService,
        loggerService,

        personalizationService,
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};

ContextProvider.displayName = 'ContextProvider';
