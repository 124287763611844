import styled from 'styled-components';

import constants from '../../constants.js';

const device = {
  desktop: `(min-width: ${constants.displaySizes.desktop})`,
  largeDesktop: `(min-width: ${constants.displaySizes.largeDesktop})`,
  mobile: `(max-width: ${constants.displaySizes.mobile})`,
  extraMobile: `(max-width: ${constants.displaySizes.extraMobile})`,
  smallDesktop: `(min-width: ${constants.displaySizes.smallDesktop})`,
  tablet: `(min-width: ${constants.displaySizes.tablet})`,
};
const { transitionTimingFunction } = constants.style;
const { text } = constants.ctaStyle;

interface ContainerProps {
  padding: string;
  theme: string;
  ctaType?: string;
  justification?: string;
}

interface CtaContainerProps {
  translateY: number;
  opacity: number;
  ctaStyle: string;
  isSingleCta?: boolean;
}

interface HeadingProps {
  opacity: number;
  translateY: number;
  justification: string;
}

interface SubHeadingProps {
  justification: string;
  opacity: number;
  translateY: number;
}

interface SubHeadingContentProps {
  textAlignment: string;
}

interface TextAndButtonContainerProps {
  isPrimary?: boolean;
}

export const Wrapper = styled.div`
  display: block;
`;

export const Container = styled.div<ContainerProps>`
  align-content: center;
  background-color: ${(props) =>
    props.theme === constants.theme.darkTheme ? constants.color.black : constants.color.white};
  box-sizing: content-box;
  display: grid;
  grid-template-columns: 100%;
  padding: 0 28px;
  padding-bottom: ${(props) =>
    props.padding === constants.sizes.s
      ? constants.paddingSizes.smallPadding.mobile
      : constants.paddingSizes.largePadding.mobile};
  padding-top: ${(props) =>
    props.padding === constants.sizes.s
      ? constants.paddingSizes.smallPadding.mobile
      : constants.paddingSizes.largePadding.mobile};
  a.footnoteRef {
    color: inherit;
    font-weight: inherit;
  }
  a:focus {
    outline: 5px auto Highlight !important;
    outline: 5px auto -webkit-focus-ring-color !important;
  }
  h1,
  h2 {
    a {
      vertical-align: super;
    }
  }

  sup a,
  sup a:visited,
  sup a:hover {
    color: ${(props) =>
      props.theme === constants.theme.darkTheme
        ? constants.color.white
        : constants.color.black}!important;
    text-decoration: none;
  }

  sup a:hover {
    color: ${(props) =>
      props.theme === constants.theme.darkTheme ? '#b3b3b3' : '#4c4c4c'}!important;
    text-decoration: none;
  }

  h2,
  h1 {
    overflow-wrap: break-word;
    text-align: ${(props) => props.justification};
  }
  .mtt-cta-container {
    flex-direction: column;
  }

  .animate-heading {
    opacity: 1;
    transform: translateY(0px);
    transition:
      opacity 0.5s,
      transform 0.5s;
    transition-timing-function: ${transitionTimingFunction};
  }

  .animate-subheading {
    opacity: 1;
    transform: translateY(0px);
    transition:
      opacity 0.5s,
      transform 0.75s;
    transition-timing-function: ${transitionTimingFunction};
  }

  .animate-ctas {
    transform: translateY(0px);
    opacity: 1;
    transition:
      opacity 1s,
      transform 1s;
    transition-timing-function: ${transitionTimingFunction};
  }
  h1 sup a,
  h2 sup a {
    font-size: 26px !important;
  }
  sup a {
    font-size: 16px !important;
  }
  @media ${device.mobile} {
    h2,
    h1 {
      max-width: 319px;
      font-size: 24px;
      text-align: ${(props) => props.justification};
    }
  }
  @media ${device.extraMobile} {
    h2,
    h1 {
      max-width: 319px;
      font-size: 24px;
      text-align: ${(props) => props.justification};
    }
  }

  @media ${device.tablet} {
    padding: 0 40px;
    padding-bottom: ${(props) =>
      props.padding === constants.sizes.s
        ? constants.paddingSizes.smallPadding.tablet
        : constants.paddingSizes.largePadding.tablet};
    padding-top: ${(props) =>
      props.padding === constants.sizes.s
        ? constants.paddingSizes.smallPadding.tablet
        : constants.paddingSizes.largePadding.tablet};
    h2,
    h1 {
      max-width: 672px;
    }
    .mtt-cta-container {
      flex-direction: ${(props) => (props.ctaType === 'button' ? 'row' : 'column')};
    }
  }
  @media ${device.smallDesktop} {
    padding: 0 60px;
    padding-bottom: ${(props) =>
      props.padding === constants.sizes.s
        ? constants.paddingSizes.smallPadding.smallDesktop
        : constants.paddingSizes.largePadding.smallDesktop};
    padding-top: ${(props) =>
      props.padding === constants.sizes.s
        ? constants.paddingSizes.smallPadding.smallDesktop
        : constants.paddingSizes.largePadding.smallDesktop};
    h2,
    h1 {
      max-width: 900px;
    }
  }
  @media ${device.desktop} {
    padding: 0 96px;
    padding-bottom: ${(props) =>
      props.padding === constants.sizes.s
        ? constants.paddingSizes.smallPadding.desktop
        : constants.paddingSizes.largePadding.desktop};
    padding-top: ${(props) =>
      props.padding === constants.sizes.s
        ? constants.paddingSizes.smallPadding.desktop
        : constants.paddingSizes.largePadding.desktop};
    h2,
    h1 {
      max-width: unset;
    }
  }
  @media ${device.largeDesktop} {
    margin: 0 auto;
    padding: 0 336px;
    max-width: 1248px;
    padding-bottom: ${(props) =>
      props.padding === constants.sizes.s
        ? constants.paddingSizes.smallPadding.largeDesktop
        : constants.paddingSizes.largePadding.largeDesktop};
    padding-top: ${(props) =>
      props.padding === constants.sizes.s
        ? constants.paddingSizes.smallPadding.largeDesktop
        : constants.paddingSizes.largePadding.largeDesktop};
  }
`;

export const ContentContainer = styled.div`
  height: auto;
`;

export const CtaContainer = styled.div<CtaContainerProps>`
  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: translateY(100px);
  width: 100%;
  justify-content: center;
  > div {
    :last-child {
      margin-right: 0;
    }
  }
  @media ${device.tablet} {
    flex-direction: ${(props) => (props.ctaStyle === text ? 'column' : 'row')};
    > div {
      margin-right: 0;
    }
  }
  @media ${device.smallDesktop} {
    flex-direction: ${(props) => (props.ctaStyle === text ? 'column' : 'row')};
    > div {
      margin-right: ${(props) => (props.isSingleCta ? '0' : '16px')};
      :last-child {
        margin-right: 0;
      }
    }
  }
  @media ${device.desktop} {
    flex-direction: ${(props) => (props.ctaStyle === text ? 'column' : 'row')};
  }
  @media ${device.largeDesktop} {
    flex-direction: ${(props) => (props.ctaStyle === text ? 'column' : 'row')};
  }
  @media (prefers-reduced-motion: reduce) {
    transition: none;
    transition-timing-function: none;
    transition-delay: 0;
  }
`;

export const AnimatedHeadingContainer = styled.div<HeadingProps>`
  justify-items: ${(props) => props.justification};
  opacity: 0;
  transform: translateY(100px);
  @media (prefers-reduced-motion: reduce) {
    transition: none;
    transition-timing-function: none;
    transition-delay: 0;
  }
  @media ${device.tablet} {
    max-width: 800px;
  }
  @media ${device.smallDesktop} {
    max-width: 1080px;
  }
  @media ${device.desktop} {
    max-width: 1100px;
  }
  @media ${device.largeDesktop} {
    max-width: 1100px;
  }
`;

export const AnimatedSubHeadingContainer = styled.div<SubHeadingProps>`
  display: grid;
  justify-items: ${(props) => props.justification};
  opacity: 0;
  transform: translateY(100px);
  width: 100%;
  @media (prefers-reduced-motion: reduce) {
    transition: none;
    transition-timing-function: none;
    transition-delay: 0;
  }
`;

export const SubHeadingContent = styled.div<SubHeadingContentProps>`
  max-width: 1248px;
  text-align: ${(props) => props.textAlignment};
  @media ${device.tablet} {
    width: ${(props) => (props.textAlignment !== 'center' ? '601px' : '100%')};
  }
  @media ${device.smallDesktop} {
    width: ${(props) => (props.textAlignment !== 'center' ? '743px' : '100%')};
  }
  @media ${device.desktop} {
    width: ${(props) => (props.textAlignment !== 'center' ? '745px' : '100%')};
  }
  @media ${device.largeDesktop} {
    width: ${(props) => (props.textAlignment !== 'center' ? '1026px' : '100%')};
  }
`;

export const TextContainer = styled.div<TextAndButtonContainerProps>`
  margin-bottom: ${(props) => (props.isPrimary ? '16px' : '0px')};
  a:focus {
    outline: 5px auto Highlight !important;
    outline: 5px auto -webkit-focus-ring-color !important;
  }
`;

export const ButtonContainer = styled.div<TextAndButtonContainerProps>`
  width: 248px;
  margin-bottom: ${(props) => (props.isPrimary ? '17px' : '0px')};
  a:focus {
    outline: 5px auto Highlight !important;
    outline: 5px auto -webkit-focus-ring-color !important;
  }
  @media ${device.tablet} {
    margin-bottom: 0px;
    margin-right: ${(props) => (props.isPrimary ? '16px' : '0px')};
  }
  @media ${device.smallDesktop} {
    margin-bottom: 0px;
    margin-right: ${(props) => (props.isPrimary ? '10px' : '0px')};
  }
  @media ${device.desktop} {
    margin-bottom: 0px;
    margin-right: ${(props) => (props.isPrimary ? '16px' : '0px')};
  }
  @media ${device.largeDesktop} {
    margin-bottom: 0px;
    margin-right: ${(props) => (props.isPrimary ? '16px' : '0px')};
  }
`;
