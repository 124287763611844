/* eslint-disable react/function-component-definition */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React, { useContext } from 'react';
import { ContentServiceV1 } from '@volkswagen-onehub/audi-content-service';
import { App } from '@volkswagen-onehub/oneaudi-os-react';
import { TrackingHelperWrapper } from '../../trackingUtils/TrackingHelperWrapper';
import MinimalisticTypographicTeaser from '../MinimalisticTypographicTeaser';
import { ContentContext } from '../../context';

import mockContent from '../../demo/editorPresets.json';

interface ClickEventProps {
  updateDatalayer: () => void;
  trackingWrapper: TrackingHelperWrapper;
  content?: any;
  contentService: ContentServiceV1 | undefined;
}

export default function ClickEvent({ updateDatalayer, trackingWrapper }: ClickEventProps) {
  const trackExitLink = (component: string, url: string) =>
    trackingWrapper.getTrackingServiceHelper().sendExitLinkEvent(component, url);
  const handleClick = (id: string) => trackingWrapper.getTrackingServiceHelper().sendClickEvent(id);

  const appConfig: { [k: string]: any } = { appName: __FEATURE_APP_NAME__ };

  const context = useContext(ContentContext);

  return (
    <div>
      <App config={appConfig}>
        <MinimalisticTypographicTeaser
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          content={context.content ? context.content : mockContent}
          updateDatalayer={updateDatalayer}
          trackingWrapper={trackingWrapper}
          trackExitLink={trackExitLink}
          handleClick={handleClick}
        />
      </App>
    </div>
  );
}
