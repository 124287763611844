import { EditorJsonContent, AEMHeadlessContent } from './contentInterfaces';

const getValidContentStructure = (
  content: EditorJsonContent | AEMHeadlessContent,
): EditorJsonContent => {
  if (content?.__type !== 'aem-headless' || !content?.__type) {
    return { ...content };
  }

  return convertHeadlessContentToEditorJsonFormat(content);
};

const convertHeadlessContentToEditorJsonFormat = (
  content: AEMHeadlessContent,
): EditorJsonContent => {
  const themeContent = { theme: content?.fields?.theme || '' };

  const justificationSection = { justification: content?.fields?.justification || '' };

  const paddingSection = { padding: content?.fields?.padding || '' };

  const animateSection = { animate: content?.fields?.animate || '' };

  const sectionHeaderContent = {
    headline: content?.fields?.headlineText || '',
    headlineTag: content?.fields?.headlineSize || '',
    subhead: content?.fields?.subheadlineText || '',
  };

  const sectionHeaderCTAs = {
    style: content?.fields?.ctaStyle || '',
    cta1: {
      text: content?.fields?.cta1Text || '',
      link: content?.fields?.cta1Link || '',
      openLinkInNewWindow: content?.fields?.cta1OpenInNewWindow || false,
    },
    cta2: {
      text: content?.fields?.cta2Text || '',
      link: content?.fields?.cta2Link || '',
      openLinkInNewWindow: content?.fields?.cta2OpenInNewWindow || false,
    },
  };

  const legalText = {
    wltpKey: content?.fields?.wltpKeys || [],
    mediaDisclaimer: content?.fields?.mediaDisclaimer || '',
  };

  const contentInEditorJsonFormat: EditorJsonContent = {
    themeContent,
    justificationSection,
    paddingSection,
    animateSection,
    sectionHeaderContent,
    sectionHeaderCTAs,
    legalText,
  };

  return contentInEditorJsonFormat;
};

export default getValidContentStructure;
